<template>
  <v-container fluid>
    <SelectCountry
      :open="selectCountryDialog"
      @closeSelectCountry="selectCountryDialog = false"
      :type="'/drivers/new'"
    />
    <v-layout justify-space-around wrap>
      <v-avatar tile color="#ffa500" class="total avatar">
        <span class="white--text breakword">
          Total Drivers
          <span class="count">{{ totalDrivers || "0" }}</span>
        </span>
      </v-avatar>

      <v-avatar tile color="#ffa500" class="total avatar">
        <span class="white--text breakword">
          Assigned Drivers
          <span class="count">{{ totalDriverAssigned || "0" }}</span>
        </span>
      </v-avatar>
      <v-avatar tile color="#ffa500" class="total avatar">
        <span class="white--text breakword">
          Free Drivers
          <span class="count">{{ freeDrivers || "0" }}</span>
        </span>
      </v-avatar>
      <v-flex class="text-center" md2>
        <ChargesPopUp />
      </v-flex>
      <v-flex md1 style="margin-left: 35px">
        <v-btn
          v-permissions="'register-new-driver'"
          class="white--text pull--right"
          flat
          color="orange darken-1"
          @click="checkAdd()"
        >
          <v-icon>add</v-icon>&nbsp;Register New Driver
        </v-btn>
      </v-flex>
    </v-layout>
    <v-card raised v-if="showScreen">
      <loading :active.sync="loading" />
      <v-card-text class="nospace">
        <!-- <v-layout>
          <v-flex md10></v-flex> -->
        <!-- <v-flex class="text-center" md2>
            <ChargesPopUp />
          </v-flex>
          <v-flex xs2 md2>
            <v-btn
              v-permissions="'register-new-driver'"
              class="white--text pull--right"
              flat
              color="orange darken-1"
              @click="checkAdd()"
            >
              <v-icon>add</v-icon>&nbsp;Register New Driver
            </v-btn>
          </v-flex>
        </v-layout> -->
        <v-layout style="border-top: 1px solid #efefef; height: 66px">
          <v-flex xs2 md4>
            <v-select
              v-model="searchTypes"
              :items="searchItems"
              flat
              label="Search Type"
              item-text="status"
              single-line
              class="pt-0"
              item-value="abbr"
              persistent-hint
              @change="searchByType()"
              height="10"
              return-object
              outline
            ></v-select>
          </v-flex>
          <v-flex xs2 md5 pr-3>
            <v-text-field
              height="55px"
              label="Search here.."
              outline
              :disabled="!searchTypes"
              single-line
              v-model="search.text"
              @keyup.delete="searchDriver"
              @keyup.enter="searchDriver"
              @click:append="searchDriver"
              append-icon="search"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 md3 pr-3>
            <v-select
              label="Driver Type"
              :items="JobTypes"
              class="pt-0"
              style="padding-top: 12px !important"
              v-model="jobType"
              @change="searchJobType"
              single-line
            />
          </v-flex>
          <v-flex
            xs12
            sm4
            md4
            pr-3
            v-if="this.jobType != 2 && this.jobType != 3"
          >
            <v-autocomplete
              label="Truck Type"
              :items="subTruck"
              class="pt-0"
              style="padding-top: 12px !important"
              v-model="subTruckType"
              @change="searchTruckType"
              item-text="text"
              item-value="value"
              single-line
            />
          </v-flex>
          <v-flex xs12 sm6 md3 pr-3>
            <v-select
              label="Availablity"
              :items="availableStatus"
              class="pt-0"
              @change="searchDriverStatus()"
              style="padding-top: 12px !important"
              v-model="status"
              single-line
            />
          </v-flex>
          <!-- Filter by Verification status -->
          <v-flex xs12 sm6 md3 pr-3>
            <v-select
              label="Status"
              :items="verificationStatusArr"
              class="pt-0"
              style="padding-top: 12px !important"
              v-model="verificationStatus"
              single-line
            />
          </v-flex>

          <v-flex
            xs12
            sm6
            md3
            pr-3
            v-if="this.jobType != 2 && this.jobType != 3"
          >
            <div id="multiselect-sample">
              <div class="col-lg-4 control-section">
                <div class="control-styles">
                  <ejs-multiselect
                    id="multiselect-checkbox"
                    :dataSource="preferredLocations"
                    :fields="fields"
                    :placeholder="checkWaterMark"
                    :mode="multiMode"
                    v-model="preferredLocation"
                    :closePopupOnSelect="true"
                    :showClearButton="false"
                    :popupHeight="popHeight"
                    :showDropDownIcon="showDropDownIcon"
                    :filterBarPlaceholder="filterPlaceholder"
                    :enableSelectionOrder="enableSelectionOrder"
                  ></ejs-multiselect>
                </div>
              </div>
            </div>

            <!--  <v-autocomplete
              label="Preferred Locations"
              :items="preferredLocations"
              multiple
              class="pt-0"
              style="padding-top: 12px !important; cursor: none !important"
              v-model="preferredLocation"
              @change="preferredLocationDriver()"
              single-line
            /> -->
          </v-flex>

          <v-flex xs2 md3 class="reset-btn">
            <v-card-actions class="justify-center">
              <v-btn
                class="reset-btn-color"
                flat
                prepend-icon
                color="orange darken-1"
                @click.native="resetSearch()"
              >
                Reset
                <v-icon color="orange">donut_large</v-icon>
              </v-btn>
            </v-card-actions>
          </v-flex>
        </v-layout>
        <v-data-table
          id="driversTable"
          :headers="headers"
          hide-actions
          :items="items"
          :loading="loading"
          :disable-initial-sort="true"
          :pagination.sync="pagination"
        >
          <template slot="items" slot-scope="props">
            <td :class="[props.item.isDocumentsExpiry ? 'text-red' : '']">
              {{ props.item.driverId }}
              <activityLogs
                :type="'driver'"
                :id="props.item.driverId.toString()"
              />
            </td>
            <td>
              <img
                v-if="props.item.workingCountry == 'PAK'"
                src="../../assets/pakflag.png"
                height="20px"
              /><img
                v-if="props.item.workingCountry == 'KSA'"
                src="../../assets/ksaflag.png"
                height="20px"
              /><img
                v-if="props.item.workingCountry == 'UAE'"
                src="../../assets/Uaeflag.png"
                height="20px"
              />
              <img
                v-if="props.item.workingCountry == 'UAE LA'"
                src="../../assets/Uaeflag.png"
                height="20px"
                style="border-radius: 50%"
              /><br />{{ props.item.workingCountry }}
            </td>
            <td>
              {{ getJobType(props.item.jobType) || "Land" }}
              {{
                props.item.jobType == "1" && props.item.borderType
                  ? "(" + getBorderType(props.item.borderType) + ")"
                  : ""
              }}
            </td>
            <td
              v-if="props.item.firstName != null && props.item.lastName != null"
            >
              {{ props.item.firstName + " " + props.item.lastName }}<br />
              <span
                v-if="
                  props.item.phoneNo != null && props.item.countryCode != null
                "
              >
                {{ props.item.countryCode + "-" + props.item.phoneNo }}
                <a
                  v-if="props.item.isChatAvailable"
                  v-permissions="'drivers/true'"
                  flat
                  prepend-icon
                  target="_blank"
                  v-bind:href="
                    'https://support.trukkin.com/#!/messages?key=' +
                    t +
                    '&driverId=' +
                    props.item._id
                  "
                  title="WhatsApp"
                >
                  <img
                    src="../../assets/whatsApp.png"
                    style="margin-top: 8px"
                    height="15"
                    width="15"
                  />
                </a>
                <a
                  v-if="!props.item.isChatAvailable"
                  v-permissions="'drivers/true'"
                  flat
                  prepend-icon
                  target="_blank"
                  title="No message present."
                  aria-disabled="true"
                >
                  <img
                    src="../../assets/disableWhatApp.png"
                    style="margin-top: 8px"
                    height="15"
                    width="15"
                  />
                </a>
              </span>
            </td>

            <td v-if="!props.item.truckPrefix && props.item.truckNumber">
              {{ props.item.truckNumber }} <br />
              {{
                props.item.subTruckType
                  ? getText(subTruck, props.item.subTruckType)
                  : ""
              }}
            </td>
            <!-- <td v-else-if="props.item.truckPrefix == 'Others'">
              {{ props.item.truckNumber }} <br />
              {{
                props.item.subTruckType
                  ? getText(subTruck, props.item.subTruckType)
                  : ""
              }}
            </td> -->
            <td v-else-if="props.item.truckNumber && props.item.truckPrefix">
              {{ props.item.truckPrefix }}-{{ props.item.truckNumber }} <br />
              {{
                props.item.subTruckType
                  ? getText(subTruck, props.item.subTruckType)
                  : ""
              }}
            </td>
            <td v-else-if="props.item.truckNumber">
              {{
                props.item.subTruckType
                  ? getText(subTruck, props.item.subTruckType)
                  : ""
              }}
            </td>
            <td v-else>
              {{
                props.item.subTruckType
                  ? getText(subTruck, props.item.subTruckType)
                  : ""
              }}
            </td>
            <td v-if="props.item.ownerType">
              {{ props.item.ownerType.toString() || "Owner" }} <br />{{
                props.item.deviceType && props.item.deviceType == "ANDROID"
                  ? "DRIVER APP"
                  : props.item.deviceType
              }}
            </td>
            <td v-else>
              <br />{{
                props.item.deviceType && props.item.deviceType == "ANDROID"
                  ? "DRIVER APP"
                  : props.item.deviceType
              }}
            </td>
            <td v-if="!props.item.availabilityStatus">
              <v-tooltip
                top
                class="milestone"
                content-class="tooltip-op"
                color="orange"
              >
                <template slot="activator">Assigned</template>
                <span class="tooltip"
                  >Assignment Id : {{ props.item.assignId }}</span
                >
              </v-tooltip>
            </td>
            <td v-if="props.item.availabilityStatus">Free</td>

            <td>
              <span v-if="props.item.accountVerified == true">Verified</span>
              <span v-if="props.item.accountVerified == false"
                >Not Verified</span
              >
            </td>

            <td class>
              <button
                v-permissions="'view-profile-driver'"
                @click="checkDetail(props.item.driverId)"
                target="_blank"
                class="track-ass-btn mr-1"
              >
                Details
              </button>

              <button
                v-permissions="'view-profile-driver'"
                v-if="props.item.accountVerified == false"
                @click="
                  verified(props.item.driverId, 'true', props.item.isBlocked)
                "
                :disabled="loading"
                target="_blank"
                class="view-ass-btn mr-1"
              >
                Verify
              </button>
              <button
                @click="
                  verified(
                    props.item.driverId,
                    props.item.accountVerified.toString(),
                    'false'
                  )
                "
                v-if="props.item.isBlocked"
                class="track-ass-btn yellow-button my-1"
                title="Unblock Driver"
              >
                Unblock
              </button>

              <button
                @click="
                  verified(
                    props.item.driverId,
                    props.item.accountVerified.toString(),
                    'true'
                  )
                "
                v-if="!props.item.isBlocked"
                class="track-ass-btn red-button"
                title="Block Driver"
              >
                Block
              </button>
            </td>
          </template>

          <v-alert
            slot="no-results"
            outline
            :value="isNoSearchResult"
            color="blue"
            icon="warning"
            >Your search for "{{ search }}" found no results.</v-alert
          >
        </v-data-table>
        <pagination
          :totalPages="totalPages"
          :pages="this.pages"
          @pageNo="page"
          :url="`/dmsAdmin/driverList`"
          :callback="callback"
          :action="`driver`"
          :componentKey="componentKey"
          :payload="payload"
          :type="1"
          v-on:pagination-load="checkLoading"
        />
      </v-card-text>
      <success-dialog
        :content="message"
        :show="success"
        :onclose="closeMessage"
      />

      <v-snackbar
        :timeout="6000"
        bottom
        color="red darken-2"
        v-model="error"
        class="white--text"
        v-if="error"
        >{{ error }}</v-snackbar
      >
    </v-card>
  </v-container>
</template>

<script>
import SelectCountry from "../Common/SelectCountry";
import Vue from "vue";
import _ from "lodash";
import { EventBus } from "../../event-bus.js";
import { driverList } from "@/constants/datatable-headers.js";
import Swal from "sweetalert2";
import ChargesPopUp from "@/components/Pop-ups/ChargesPopUp";
import activityLogs from "../Common/activityLogs";
import SuccessDialog from "@/components/Common/SuccessDialog";
import ErrorBox from "@/components/Common/ErrorBox";
import checkPermissionsMixin from "@/mixins/checkPermissionsMixin";
import DriverProfileForm from "./DriverProfileForm";
import pagination from "@/components/Pagination/pagination";
import { StorageKeys, JobTypes, TruckTypes } from "../../constants/constants";
import Loading from "vue-loading-overlay";
import {
  MultiSelectPlugin,
  CheckBoxSelection,
} from "@syncfusion/ej2-vue-dropdowns";
Vue.use(MultiSelectPlugin);
import { transporter, driver } from "../../constants/api-urls";
export default {
  mixins: [checkPermissionsMixin],
  created() {
    if (localStorage.getItem("workingCountries")) {
      this.workingCountries = JSON.parse(
        localStorage.getItem("workingCountries")
      );
      let workingCountry = [];
      this.workingCountries.forEach((key, index) => {
        if (key.isSelected) {
          workingCountry.push(key.value);
        }
      });
      this.workingCountry = JSON.stringify(workingCountry);
      this.payload = {
        workingCountry: this.workingCountry,
      };
      this.searchItems = [
        { status: "Driver Name", abbr: "driverName" },
        { status: "Driver ID", abbr: "driverId" },
        { status: "Emirates ID", abbr: "emiratesId" },
        { status: "Phone Number", abbr: "mobileNumber" },
        { status: "Truck Number", abbr: "truckNumber" },
        { status: "Passport Number", abbr: "passPortNumber" },
        { status: "License Number", abbr: "licenseNumber" },
      ];
      if (workingCountry.length === 1 && workingCountry.indexOf("PAK") >= 0) {
        this.searchItems = [
          { status: "Driver Name", abbr: "driverName" },
          { status: "Driver ID", abbr: "driverId" },
          { status: "Emirates ID", abbr: "emiratesId" },
          { status: "Phone Number", abbr: "mobileNumber" },
          { status: "Truck Number", abbr: "truckNumber" },
        ];
      }
      this.getDashboardData();
    }

    EventBus.$on("countryChanged", (val) => {
      this.workingCountry = JSON.stringify(val);
      this.getDashboardData();
      this.payload = {
        workingCountry: this.workingCountry,
      };
      this.searchItems = [
        { status: "Driver Name", abbr: "driverName" },
        { status: "Driver ID", abbr: "driverId" },
        { status: "Emirates ID", abbr: "emiratesId" },
        { status: "Phone Number", abbr: "mobileNumber" },
        { status: "Truck Number", abbr: "truckNumber" },
        { status: "Passport Number", abbr: "passPortNumber" },
        { status: "License Number", abbr: "licenseNumber" },
      ];
      if (val.length === 1 && val.indexOf("PAK") >= 0) {
        this.searchItems = [
          { status: "Driver Name", abbr: "driverName" },
          { status: "Driver ID", abbr: "driverId" },
          { status: "Emirates ID", abbr: "emiratesId" },
          { status: "Phone Number", abbr: "mobileNumber" },
          { status: "Truck Number", abbr: "truckNumber" },
        ];
      }
    });
    this.JobTypes = JobTypes;
    this.subTruck = TruckTypes;

    if (localStorage.getItem("current-driver-page-status")) {
      this.payload = JSON.parse(
        localStorage.getItem("current-driver-page-status")
      );
    }
    if (localStorage.getItem("payloadDriver")) {
      let storedPayload = JSON.parse(localStorage.getItem("payloadDriver"));
      delete storedPayload["workingCountry"];
      storedPayload.workingCountry = this.workingCountry;
      this.payload = storedPayload;
    }
    if (localStorage.getItem("DriverJobType")) {
      this.jobType = localStorage.DriverJobType;
      if (this.jobType == 1 && this.workingCountry == "PAK") {
        this.searchItems = [
          { status: "Driver Name", abbr: "driverName" },
          { status: "Driver ID", abbr: "driverId" },
          { status: "Emirates ID", abbr: "emiratesId" },
          { status: "Phone Number", abbr: "mobileNumber" },
          { status: "Truck Number", abbr: "truckNumber" },
          { status: "Passport Number", abbr: "passPortNumber" },
          { status: "License Number", abbr: "licenseNumber" },
        ];
      }
      if (this.jobType == 2) {
        this.searchItems = [
          { status: "Driver Name", abbr: "driverName" },
          { status: "Driver ID", abbr: "driverId" },
          { status: "Emirates ID", abbr: "emiratesId" },
          { status: "Phone Number", abbr: "mobileNumber" },
          { status: "Flight Number", abbr: "truckNumber" },
        ];
      }
      if (this.jobType == 3) {
        this.searchItems = [
          { status: "Driver Name", abbr: "driverName" },
          { status: "Driver ID", abbr: "driverId" },
          { status: "Emirates ID", abbr: "emiratesId" },
          { status: "Phone Number", abbr: "mobileNumber" },
          { status: "Container Number", abbr: "truckNumber" },
        ];
      }
      this.payload.jobType = this.jobType;
    }

    if (localStorage.getItem("searchDriverStatus")) {
      this.status = localStorage.searchDriverStatus;
      this.payload.status = this.status;
    }
    if (localStorage.getItem("driverVerificationStatus")) {
      this.verificationStatus = this.payload["verificationStatus"] =
        localStorage.getItem("driverVerificationStatus");
    }
    if (localStorage.getItem("subTruckType")) {
      this.subTruckType = localStorage.subTruckType;
      this.payload.subTruckType = this.subTruckType;
    }

    if (localStorage.getItem("searchDrivertext")) {
      this.search.text = localStorage.searchDrivertext;
      this.searchTypes = JSON.parse(localStorage.searchDriverType);
    }
    if (localStorage.getItem("searchDriverPreferredLocation")) {
      // this.preferredLocation = [];
      // let values = localStorage.searchDriverPreferredLocation;
      // console.log(values);
      // this.preferredLocation.push(values);
      // console.log()
      // values = values.split(",");
      // values.forEach((item) => {
      //   this.preferredLocation.push(item);
      // });
      // this.payload.preferredLocation = this.preferredLocation;
    }
    this.$eventBus.$on("drivers", (data) => {
      this.componentKey = this.componentKey + 1;
    });
    delete this.axios.defaults.headers.common["token"];
    let token = localStorage.getItem(StorageKeys.SessionKey);
    let config = {
      headers: {
        authorization: `${token}`,
      },
    };
    this.t = config.headers.authorization;

    const url = `${this.constants.apiUrl}/driver/getPreferredLocation`;
    this.axios.get(url).then((response) => {
      if (response.status === 200) {
        response.data.data.forEach((e) => {
          this.preferredLocations.push({ value: e.name, text: e.name });
        });
      }
    });
  },
  components: {
    DriverProfileForm,
    pagination,
    activityLogs,
    Loading,
    ErrorBox,
    SuccessDialog,
    ChargesPopUp,
    SelectCountry,
  },
  data() {
    return {
      checkWaterMark: "Prefered Locations",
      selectCountryDialog: false,
      popHeight: "350px",
      multiMode: "CheckBox",
      showDropDownIcon: true,
      showSelectAll: true,
      selectAll: Boolean,
      filterPlaceholder: "Search Prefered Location",
      enableSelectionOrder: false,
      fields: { text: "text", value: "value" },
      availableStatus: [
        { text: "Free", value: "true" },
        { text: "Assigned", value: "false" },
      ],
      verificationStatusArr: [
        { text: "Verified", value: "true" },
        { text: "Not verified", value: "false" },
      ],

      verificationStatus: "",

      status: "",

      totalDrivers: 0,
      freeDrivers: 0,
      totalDriverAssigned: 0,
      showScreen: true,
      operationName: "",
      subTruck: [],
      subTruckType: "",
      workingCountry: null,
      JobTypes: [],
      jobType: "",
      processing: false,
      componentKey: 0,
      success: false,
      message: "",
      error: null,
      t: "",
      search: "",
      search: {
        results: [],
        text: "",
        active: false,
        next: null,
        prev: null,
      },
      searchItems: [],
      searchTypes: "",
      preferredLocations: [],
      preferredLocation: [],
      payload: {},
      totalPages: 0,
      pages: 1,
      loading: false,

      isNoSearchResult: false,
      pagination: {},

      headers: driverList,
      items: [],
      driverLoginDialog: false,
    };
  },
  watch: {
    preferredLocation(val) {
      if (val) {
        if (val && val.length) {
          this.preferredLocationDriver();
        } else {
          this.preferredLocationDriver();
        }
      }
      this.checkVerificationStatus();
    },
    pagination(val) {
      if (val["sortBy"] === "name") {
        if (val["descending"] === true) {
          this.payload["sortBy"] = "name";
          this.payload["sortType"] = -1;
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        } else if (val["descending"] === false) {
          this.payload["sortBy"] = "name";
          this.payload["sortType"] = 1;
          this.componentKey = this.componentKey + 1;
          this.loading = true;
        }
      }
      if (!val["sortBy"]) {
        delete this.payload["sortType"];
        delete this.payload["sortBy"];
        if (val["descending"] == null) {
          this.componentKey = this.componentKey + 1;
        }
        this.loading = true;
      }
      deep: true;
    },
    verificationStatus: function (val) {
      if (val !== "") {
        this.payload["verificationStatus"] = val;
        this.componentKey++;
        localStorage.setItem("driverVerificationStatus", val);
      }
    },
  },
  methods: {
    page(event) {
      this.pages = event;
    },
    async checkAdd() {
      this.operationName = "register-new-driver";
      let y = await this.checkOpertaionPermission(this.operationName);

      if (y) {
        if (localStorage.getItem("workingCountries")) {
          this.workingCountries = JSON.parse(
            localStorage.getItem("workingCountries")
          );
          let workingCountry = [];
          this.workingCountries.forEach((key, index) => {
            if (key.isSelected) {
              workingCountry.push(key.value);
            }
          });
          if (workingCountry.length === 1) {
            this.$router.push(`/drivers/new`);
          } else {
            this.selectCountryDialog = true;
          }
        }
      } else {
        return;
      }
    },
    async checkDetail(id) {
      this.operationName = "view-profile-driver";
      this.loading = true;
      let y = await this.checkOpertaionPermission(this.operationName);
      if (y) {
        this.goToUserProfile(id);
      } else {
        this.loading = false;
        return;
      }
    },
    getText(arr = [], val) {
      let finalEl = null;
      finalEl = arr.find((el) => el.value == val);
      return finalEl ? finalEl.text : "NA";
    },
    getBorderType(type) {
      switch (type) {
        case "Local":
          return "Local";
        case "OutOfBorder":
          return "Cross Country";
      }
    },
    getJobType(type) {
      switch (type) {
        case "1":
          return "Land";
        case "2":
          return "Air";
        case "3":
          return "Sea";
        default:
          return "Land";
      }
    },
    closeMessage() {
      this.success = false;
      this.componentKey += 1;
    },
    getDashboardData() {
      this.processing = true;
      let Url = driver.driverListCount;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        workingCountry: this.workingCountry,
      };
      this.axios.post(this.constants.apiUrl + Url, body, config).then(
        (response) => {
          this.totalDriverAssigned = response.data.data.assignedData;
          this.freeDrivers = response.data.data.freeData;
          this.totalDrivers = response.data.data.totalData;
          this.processing = false;
        },
        (error) => {
          this.success = false;
          this.error = error.response.data.message;
          setTimeout(() => {
            this.error = "";
          }, 6000);
        }
      );
    },

    verified(id, status, status1) {
      this.processing = true;
      this.loading = true;
      let Url = transporter.blockAndVerifyDriver;
      delete this.axios.defaults.headers.common["token"];
      let token = localStorage.getItem(StorageKeys.SessionKey);
      let config = {
        headers: {
          authorization: `bearer ${token}`,
        },
      };

      let body = {
        driverId: id.toString(),
        accountVerified: status,
        isBlocked: status1.toString(),
      };
      this.axios.post(this.constants.apiUrl + Url, body, config).then(
        (response) => {
          this.success = true;
          this.loading = false;
          this.processing = false;
        },
        (error) => {
          this.success = false;
          this.loading = false;
          this.error = error.response.data.message;
          setTimeout(() => {
            this.error = "";
          }, 6000);
        }
      );
    },

    paginationPayload(event) {
      this.loading = event;
      this.payload = event;
      localStorage.removeItem("current-customer-page-status");
    },
    getDefaultUrl() {
      const { serverUrl } = this.constants;
      const { limit = 20, skip = 0 } = this;
      return `${serverUrl}/api/drivers/?limit=${limit}&skip=${skip}`;
    },
    goToUserProfile(id) {
      this.$router.push(`/drivers/${id}`);
    },
    searchJobType() {
      if (
        this.jobType != "1" &&
        (this.searchTypes.abbr == "licenseNumber" ||
          this.searchTypes.abbr == "passPortNumber")
      ) {
        this.search.text = "";
        this.searchTypes = "";
        this.subTruckType = "";
        (this.status = ""),
          (this.payload = {
            jobType: this.jobType,

            workingCountry: this.workingCountry,
          });
        localStorage.removeItem("searchDriverPreferredLocation");
        localStorage.removeItem("searchDriverStatus");
        localStorage.removeItem("searchDriverType");
        localStorage.removeItem("DriverJobType");
        localStorage.removeItem("subTruckType");
        return;
      }
      if (this.jobType == 2 || this.jobType == 3) {
        delete this.payload.subTruckType;
        this.subTruckType = "";
      }
      if (this.jobType) {
        localStorage.setItem("DriverJobType", this.jobType);
        if (this.jobType == 1 && this.workingCountry != "PAK") {
          this.searchItems = [
            { status: "Driver Name", abbr: "driverName" },
            { status: "Driver ID", abbr: "driverId" },
            { status: "Emirates ID", abbr: "emiratesId" },
            { status: "Phone Number", abbr: "mobileNumber" },
            { status: "Truck Number", abbr: "truckNumber" },
            { status: "Passport Number", abbr: "passPortNumber" },
            { status: "License Number", abbr: "licenseNumber" },
          ];
        }
        if (this.jobType == 2) {
          this.searchItems = [
            { status: "Driver Name", abbr: "driverName" },
            { status: "Driver ID", abbr: "driverId" },
            { status: "Emirates ID", abbr: "emiratesId" },
            { status: "Phone Number", abbr: "mobileNumber" },
            { status: "Flight Number", abbr: "truckNumber" },
          ];
          this.preferredLocation = [];
          delete this.payload.preferredLocation;
        }
        if (this.jobType == 3) {
          this.searchItems = [
            { status: "Driver Name", abbr: "driverName" },
            { status: "Driver ID", abbr: "driverId" },
            { status: "Emirates ID", abbr: "emiratesId" },
            { status: "Phone Number", abbr: "mobileNumber" },
            { status: "Container Number", abbr: "truckNumber" },
          ];
          this.preferredLocation = [];
          delete this.payload.preferredLocation;
        }
        this.loading = true;
        this.search.active = true;
        this.$set(this.payload, "jobType", this.jobType);
      }
      this.checkVerificationStatus();
    },

    searchByType() {
      this.$emit("filters-type", this.searchTypes.abbr);
      this.search.text = "";
      this.searchDriver();
    },

    searchDriverStatus() {
      this.loading = true;
      this.search.active = true;
      if (this.jobType == 2 || this.jobType == 3) {
        delete this.payload.subTruckType;
        this.subTruckType = "";
      } else {
        this.$set(this.payload, "status", this.status);
      }
      localStorage.setItem("searchDriverStatus", this.status);
      this.checkVerificationStatus();
    },

    searchDriver() {
      if (this.search.text.trim() == localStorage.getItem("searchDrivertext")) {
        this.loading = false;
        return;
      }
      if (this.search.text.trim().length > 0) {
        //debugger
        localStorage.setItem("searchDrivertext", this.search.text);
        localStorage.setItem(
          "searchDriverType",
          JSON.stringify(this.searchTypes)
        );

        if (this.jobType == 2 || this.jobType == 3) {
          delete this.payload.subTruckType;
          this.subTruckType = "";
        }
        this.loading = true;
        this.search.active = true;
        this.$set(this.payload, "searchText", this.search.text);
      } else {
        delete this.payload.searchText;
        localStorage.removeItem("searchDrivertext");
        localStorage.setItem(
          "searchDriverType",
          JSON.stringify(this.searchTypes)
        );
        if (this.jobType == 2 || this.jobType == 3) {
          delete this.payload.subTruckType;
          this.subTruckType = "";
        }
        this.componentKey++;
        this.search.active = true;
        this.$set(this.payload, "searchType", this.searchTypes.abbr);
      }
      this.checkVerificationStatus();
    },
    checkLoading(event) {
      if (event) {
        this.loading = true;
      } else {
        this.loading = false;
      }
    },

    preferredLocationDriver() {
      if (this.preferredLocation.length) {
        localStorage.setItem(
          "searchDriverPreferredLocation",
          this.preferredLocation
        );
        this.loading = true;
        this.search.active = true;
        this.$set(this.payload, "preferredLocation", this.preferredLocation);
      } else {
        this.loading = true;
        this.search.active = true;
        delete this.payload.preferredLocation;
        this.componentKey++;
      }
      this.checkVerificationStatus();
    },

    searchTruckType() {
      if (this.subTruckType) {
        localStorage.setItem("subTruckType", this.subTruckType);
        this.loading = true;
        this.search.active = true;
        this.$set(this.payload, "subTruckType", this.subTruckType);
      }
      this.checkVerificationStatus();
    },

    checkVerificationStatus() {
      if (this.verificationStatus !== "") {
        this.payload["verificationStatus"] = this.verificationStatus;
        this.componentKey++;
        localStorage.setItem(
          "driverVerificationStatus",
          this.verificationStatus
        );
      }
    },

    resetSearch() {
      if (
        !this.searchTypes &&
        !this.search.text &&
        !this.preferredLocation.length &&
        !this.jobType &&
        !this.status &&
        !this.subTruckType &&
        !this.verificationStatus
      ) {
        this.loading = false;
        return;
      } else {
        this.verificationStatus = "";
        this.search.active = false;
        this.search.text = "";
        this.searchTypes = "";
        this.subTruckType = "";
        (this.status = ""), (this.jobType = "");
        this.preferredLocation = [];
        this.payload = {
          workingCountry: this.workingCountry,
        };
        localStorage.removeItem("searchDriverPreferredLocation");
        localStorage.removeItem("searchDriverStatus");
        localStorage.removeItem("searchDriverType");
        localStorage.removeItem("searchDrivertext");
        localStorage.removeItem("DriverJobType");
        localStorage.removeItem("subTruckType");
        localStorage.removeItem("driverVerificationStatus");
        if (this.workingCountry != "PAK") {
          this.searchItems = [
            { status: "Driver Name", abbr: "driverName" },
            { status: "Driver ID", abbr: "driverId" },
            { status: "Emirates ID", abbr: "emiratesId" },
            { status: "Phone Number", abbr: "mobileNumber" },
            { status: "Truck Number", abbr: "truckNumber" },
            { status: "Passport Number", abbr: "passPortNumber" },
            { status: "License Number", abbr: "licenseNumber" },
          ];
        } else {
          this.searchItems = [
            { status: "Driver Name", abbr: "driverName" },
            { status: "Driver ID", abbr: "driverId" },
            { status: "Emirates ID", abbr: "emiratesId" },
            { status: "Phone Number", abbr: "mobileNumber" },
            { status: "Truck Number", abbr: "truckNumber" },
          ];
        }
      }
    },

    callback(res) {
      localStorage.setItem("payloadDriver", JSON.stringify(this.payload));
      this.loading = false;
      this.items = res.list;
      this.totalPages = Math.ceil(res.totalData / 20);
      this.pagination.rowsPerPage = 20;
      // window.scrollTo(0, 0);
    },
    searchCallback(res) {
      this.loading = false;
      this.items = res.list;
    },
  },
  provide: {
    multiselect: [CheckBoxSelection],
  },
};
</script>

<style lang="scss">
#driversTable .theme--light.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
  color: white !important;
  margin-left: 10px !important;
}
#driversTable
  .theme--light.v-datatable
  thead
  th.column.sortable.active
  .v-icon {
  opacity: 1 !important;
  color: white !important;
  margin-left: 10px !important;
}
.e-multiselect.e-input-group.e-control-wrapper.e-checkbox {
  width: 90% !important;
}
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before {
  background: darkorange !important;
}
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after {
  background: darkorange !important;
}
div.e-input-focus::before,
div.e-input-focus::after {
  background: darkorange !important;
}
input.e-dropdownbase::placeholder {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54) !important;
  font-weight: 500;
}
.e-multi-select-wrapper .e-close-icon-hide .e-down-icon {
  background: darkorange !important;
}
.e-input-in-wrap::after {
  background: darkorange !important;
}
.e-checkbox-wrapper .e-frame.e-check,
.e-css.e-checkbox-wrapper .e-frame.e-check {
  background-color: darkorange !important;
}
.table-header-item1 {
  font-size: 16px !important;
  color: #484848;
  font-weight: bold;
}

td {
  font-size: 16px !important;
}

thead {
  background: #efefef;
}
.page-count-span {
  float: right;
  margin-top: 26px;
  color: darkorange;
}
.text-red {
  color: red;
}
.p-16 {
  padding: 0 16px !important;
}
.pull--right {
  float: right;
}
.view-ass-btn {
  text-decoration: none;
  color: #ffffff;
  background-color: #c87ffe;
  border: 2px solid indigo;
  padding: 2px 5px;
  border-radius: 5px;
}
.search {
  float: left;
}

.country {
  display: inline-block;
}

.filter {
  padding: 20px;
  margin: 0 auto;
}
.reset {
  margin-top: 10px;
}
.v-datatable thead th.column.sortable .v-icon {
  opacity: 0.3 !important;
}
.control-styles {
  margin: 0 auto;
  width: 300px;
  padding-top: 18px;
}
</style>
